import { useState, useEffect } from 'react';

import { OrderForm } from '../OrderForm/OrderForm';
import emailjs from '@emailjs/browser';
import { Spinner } from 'react-bootstrap';

import styles from './Main.module.css';
import { About } from '../About/About';
import { Landing } from '../Landing/Landing';
import { CardsExample } from '../CardsExample/CardsExample';
import { EventsToTrack } from '../EventsToTrack/EventsToTrack';


export const Main = () => {
    useEffect(() => {
        if (window.fbq) {
            console.log('just registered page view')
            EventsToTrack.pageView();
        } else{
            console.log("pixel not loaded yet");
        }
    }, []);

    const [hasOrdered, setHasOrdered] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(true);
    const [isLoading, setIsLoading] = useState(false);


    const onOrderSubmit = async (data) => {
        setHasOrdered(true);
        delete data.agreed;
        const order = {...data};
        order.firstName = data.firstName[0].toUpperCase() + data.firstName.slice(1);
        order.lastName = data.lastName[0].toUpperCase() + data.lastName.slice(1);
        
        console.log(order);
        
        const serviceId = process.env.REACT_APP_EMAIL_ANTISMOKING_CARDS_SERVICE_ID;
        const orderTemplate = process.env.REACT_APP_EMAIL_ANTISMOKING_CARDS_ORDER_TEMPLATE;
        const responseTemplate = process.env.REACT_APP_EMAIL_ANTISMOKING_CARDS_RESPONSE_TEMPLATE;
        const publicKey = process.env.REACT_APP_ANTISMOKING_CARDS_PUBLIC_KEY;

        console.log(serviceId);
        console.log(orderTemplate);
        console.log(responseTemplate);
        console.log(publicKey);
        
        setIsLoading(true);

        EventsToTrack.purchase();

        emailjs.send(serviceId, orderTemplate, order, {
            publicKey: publicKey
        }).then(
            (response) => {
              setTimeout(() => {
                    setIsLoading(false);
                    setIsSuccessful(true);
                }, 1000);
              console.log('SUCCESS!', response.status, response.text);
              emailjs.send(serviceId, responseTemplate, order, {
                publicKey: publicKey
                }).then((response) => {
                    console.log('Confirmation Sent!', response.status);
                }, (error) => {
                    console.log('OOPS', error);
                });
            },
            (error) => {
              setIsLoading(false);
                setIsSuccessful(false);
                setTimeout(() => {
                setHasOrdered(false);
                }, 1000);
              console.log('FAILED...', error);
            },
         );
    }

    return(
        <>
            <Landing/>
            <About/>
            <CardsExample/>
            {
            !hasOrdered && <OrderForm onSubmitHandler={onOrderSubmit}/>
            }
            {
            hasOrdered && 
            <div className={styles.alertDiv}>
                {
                    (!isLoading && isSuccessful) && 
                    <>
                        <img className={styles.responseImg} src="/images/Antismoking-cards/Success-cards.png" alt="success" />
                    </>
                }
                {
                    (!isLoading && !isSuccessful) && 
                    <>
                        <img className={styles.responseImg} src="/Error.png" alt="error" />
                    </>
                }
                {
                    isLoading && 
                    <Spinner className={styles.spinner} animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                }
            </div>
            }
        </>
    )
}