import { Link } from 'react-router-dom';
import styles from './Footer.module.css'

export const Footer = () => {
  return (
    <div style={{backgroundColor: '#e8ffc7', borderTop: '2px solid #008037'}}>
        <div className="container">
        <footer
            className="text-center text-lg-start text-black pb-3"
            style={{backgroundColor: '#e8ffc7'}}
        >
            <div className="container p-4 pb-0">
            <section className="">
                <div className="row">
                    <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                        <h6 className={`text-uppercase mb-4 font-weight-bold ${styles.title}`}>
                            АНТИСТРЕС ТЕРАПИЯ
                        </h6>
                        <p className={`text-black ${styles.otherText}`}>
                            Мъдрости от Пепелта е сборник от сентенции, подбрани с цел да вдъхновяват и мотивират читателите. 
                        </p>
                    </div>

                    <hr className="w-100 clearfix d-md-none" />

                    <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                        <h6 className={`text-uppercase mb-4 font-weight-bold ${styles.title}`}>ПРАВИЛА</h6>
                        <p>
                            <Link to="/antistres-kutiya/terms" target="_blank" className={`text-black ${styles.otherText}`}>Общи условия</Link>
                        </p>
                        <p>
                            <Link to="/antistres-kutiya/personal-info-rules" target="_blank" className={`text-black ${styles.otherText}`}>Лични данни</Link>
                        </p>
                    </div>

                    <hr className="w-100 clearfix d-md-none" />

                    <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                        <h6 className={`text-uppercase mb-4 font-weight-bold ${styles.title}`}>КОНТАКТИ</h6>
                        <p className={`text-black ${styles.otherText}`}>
                        <i className="fas fa-home mr-3"></i> Велико Търново, ВТ 5000, BG
                        </p>
                        <p className={`text-black ${styles.otherText}`}> 
                        <i className="fas fa-envelope mr-3"></i> madrostiotpepelta@gmail.com
                        </p>
                        <p className={`text-black ${styles.otherText}`}>
                        <i className="fas fa-phone mr-3"></i> +359 882 511 650
                        </p>
                    </div>

                    <hr className="w-100 clearfix d-md-none" />

                    <div 
                    className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3"
                    style={{display: 'flex', flexDirection: 'column'}}
                    >
                        <h6 className={`text-uppercase mb-4 font-weight-bold text-center ${styles.title}`}>ПОСЛЕДВАЙТЕ НИ</h6>

                        <div style={{display: 'flex', justifyContent:'center', flexWrap: 'wrap'}}>
                            <a
                            className="btn btn-dark btn-floating m-1 rounded-circle"
                            style={{backgroundColor: '#3b5998', width: '45px', height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                            href="https://www.facebook.com/profile.php?id=61554567992121"
                            role="button"
                            >
                            <i className="fab fa-facebook-f"></i>
                            </a>


                            <a
                            className="btn btn-dark btn-floating m-1 rounded-circle"
                            style={{backgroundColor: '#ac2bac', width: '45px', height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                            href="https://www.instagram.com/madrostiotpepelta/"
                            role="button"
                            >
                            <i className="fab fa-instagram"></i>
                            </a>

                            <a
                            className="btn btn-dark btn-floating m-1 rounded-circle"
                            style={{backgroundColor: 'red', width: '45px', height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                            href="https://www.youtube.com/channel/UCbN5YSCiFYvDY_9vp6g5C6w"
                            role="button"
                            >
                            <i className="fab fa-youtube"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            </div>

            <div
            className={`text-center p-3 rounded-pill ${styles.otherText}`}
            style={{backgroundColor: 'rgba(0, 128, 55, 0.2)'}}
            >
            © 2024 Copyright:&nbsp;
            <a className={`text-black ${styles.otherText}`} href="https://k-kostadinov.store/">
              Антистрес Терапия
            </a>
            </div>
        </footer>
        </div>
    </div>
  );
};