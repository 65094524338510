import { Routes, Route } from 'react-router-dom'

import { Main } from './Main/Main'
import { PersonalInformation } from './PersonalInformation/PersonalInformation'
import { Footer } from './Footer/Footer'
import { NotFound } from '../404'
import { Terms } from './Terms/Terms'

export const AntistressTherapy = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path="terms" element={<Terms />} />
        <Route path="personal-info-rules" element={<PersonalInformation />} />
        <Route path="*" element={<NotFound/>}/>
      </Routes>
      <Footer/>
    </>
  )
}