import styles from "./PersonalInformation.module.css";

export const PersonalInformation = () => {
  return (
    <div className={styles.personalInfoRules}>
      <div className={styles.container}>
        <h1 className={styles.title}>ДЕКЛАРАЦИЯ ЗА ЗАЩИТА НА ЛИЧНИТЕ ДАННИ</h1>
        <p className={styles.rule}>
          Защитата на личните Ви данни ни е важна. Затова обработваме Вашата
          информация изключително въз основа на приложимото законодателство,
          конкретно GDPR, Закона за защита на лични данни (ЗЗЛД) и Закона за
          електронната търговия (ЗЕТ). В тази декларация ви информираме за
          най-важните аспекти от обработването на Вашите данни на нашия
          уебсайт.Какво се случва с данните Ви, след като се свържете се нас?
          Когато ни пишете чрез формулярите на нашия уебсайт или ни пращате
          имейл, ние съхраняваме данните Ви за период от една година, така че да
          можем да обработим искането Ви, както и за да отговорим на Ваши
          последващи въпроси. Няма да споделяме тази информация с трети лица без
          Вашето изрично съгласие!
        </p>
        <h2 className={styles.subtitle}>Съхраняване на Вашите данни</h2>
        <p className={styles.rule}>
          Обръщаме внимание, че с цел облекчаване на процеса по поръчка и
          покупка, както и последващото изпълнение на договора с Вас,
          съхраняваме Вашите имена, адрес и имейл адрес. Предоставените от Вас
          данни са ни нужни, за да изпълним своите договорни или преддоговорни
          задължения спрямо Вас. Не предоставяме тези данни на трети лица, с
          изключение на куриерската фирма, с която Ви доставяме покупката. При
          незавършена поръчка изтриваме въведените по нея данни. При завършена
          поръчка и изпълнен от наша страна договор на основание чл. 6, ал. 1,
          букви (а) и (б) от GDPR съхраняваме всички данни по правоотношението
          до изтичане на предвидения в чл. 12 от Закона за счетоводството (ЗСч)
          10-годишен срок.
        </p>
        <h2 className={styles.subtitle}>Бисквитки</h2>
        <p className={styles.rule}>
          Нашият уеб сайт не използва така наречените „бисквитки“. Анализ на
          интернет трафика Нашият сайт не анализира потребителския интернет
          трафик чрез Google Analytics.
        </p>
      </div>
    </div>
  );
};