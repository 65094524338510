import { Form, Button, FloatingLabel } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styles from "./OrderForm.module.css";

export const OrderForm = (props) => {
  const { register, handleSubmit, formState: {errors} } = useForm({
    mode: "onTouched",
    defaultValues:{
      firstName:'',
      lastName:'',
      address:'',
      email:'',
      phoneNumber:'',
      count: 1,
      agreed: false
  }});
  

  return (
    <div id="order-form" className={styles.OrderSection}>
      <h2 className={styles.title}>Направете Поръчка</h2>
      <Form className={styles.order} onSubmit={handleSubmit(props.onSubmitHandler)}>
        <h3 className="h1" style={{color: '#9f1312', fontFamily: 'BootsHaus-Regular'}}>*Цената на картите е <s>17лв.</s> <p style={{ display:'inline', fontSize: "2.9rem", fontWeight: 600}}>14лв.</p></h3>
        <div className={styles.tuple}>
          <div className={styles.part}>
            <Form.Group>
              <FloatingLabel label="Име" className="mt-3">
                  <Form.Control name="firstName" type="text" placeholder="Въведете име" 
                    {...register("firstName", {required: true, minLength: 2, maxLength: 20})}
                    />
              </FloatingLabel>
            </Form.Group>
            {errors.firstName &&  
              <div>
                <Form.Text className="text-danger">
                  Дължината на името трябва да е между 2 и 20 символа
                </Form.Text>                        
              </div>         
            }
          </div>
          <div className={styles.part}>
            <Form.Group>
              <FloatingLabel label="Фамилия" className="mt-3">
                  <Form.Control name="lastName" type="text" placeholder="Въведете фамилия" 
                    {...register("lastName", {required: true, minLength: 2, maxLength: 20})}
                    />
              </FloatingLabel>
            </Form.Group>
              {errors.lastName &&  
                <div>
                  <Form.Text className="text-danger">
                    Дължината на фамилията трябва да е между 2 и 20 символа
                  </Form.Text>                        
                </div>         
              }
            </div>
        </div>

        <Form.Group className="mt-3">
            <FloatingLabel label="Адрес/Офис на Speedy или Econt" className="mt-3">
                <Form.Control name="address" type="text" placeholder="Въведете адрес" 
                  {...register("address", {required: true, minLength: 5, maxLength: 150})}
                />
            </FloatingLabel>
        </Form.Group>
        {errors.address &&  
            <div>
              <Form.Text className="text-danger">
                Дължината на адреса трябва да е между 5 и 150 символа
              </Form.Text>                        
            </div>         
        }

        
          <Form.Group>
            <FloatingLabel label="Email" className="mt-3">
                <Form.Control name="email" type="text" placeholder="Въведете email" 
                  {...register("email", {required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} 
                />
            </FloatingLabel>
          </Form.Group>
          {errors.email &&  
            <div>
              <Form.Text className="text-danger">
                Невалиден email
              </Form.Text>                        
            </div>         
          }
      
        <div className={styles.tuple}>
          <div className={styles.part}>

            <Form.Group>
              <FloatingLabel label="Телефонен номер" className="mt-3">
                  <Form.Control name="phoneNumber" type="text" placeholder="Въведете номер" 
                    {...register("phoneNumber", { required: true, pattern: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/ })}
                    />
              </FloatingLabel>
            </Form.Group>
            {errors.phoneNumber &&  
              <div>
                <Form.Text className="text-danger">
                  Невалиден телефонен номер
                </Form.Text>                        
              </div>         
            }
          </div>

          <div className={styles.part}>
            <Form.Group>
              <FloatingLabel label="Брой" className="mt-3">
                  <Form.Control name="count" min="1" max="100" type="number" placeholder="Въведете брой" 
                    {...register("count", {required: true, min: 1, max: 100})}
                  />
              </FloatingLabel>
            </Form.Group>
            {errors.count &&  
              <div>
                <Form.Text className="text-danger">
                  Невалидна стойност за брой
                </Form.Text>                        
              </div>         
            }
          </div>
        </div>
        
        <Form.Group className="mt-3">
          <Form.Check 
           name="agreed" 
           className={styles.txtEtc} 
           type="checkbox" 
           label="Съгласявам се с Общите правила за обработка на данни" 
           {...register("agreed", {required: true})}
           />
        </Form.Group>
        {errors.agreed &&  
            <div>
              <Form.Text className="text-danger">
                Това поле е задължително
              </Form.Text>                        
            </div>         
        }
        <Button className={[styles.createSubmitBtn, "mt-3"]} type="submit">
          Завърши поръчка
        </Button>
      </Form>
    </div>
  );
};
